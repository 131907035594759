<template>
  <div>
    <main class="mt-8 md:mt-16 container">
    
    <div
        v-if="showErorr"
        class="
          fixed
          z-50
          inset-0
          min-h-full
          w-full
          bg-primary-black bg-opacity-75
          flex
          justify-center
          items-center
          overflow-y-hidden
        "
      >
        <div class="w-1/3 bg-surface-white p-4 rounded">
          <button
            class="text-3xl text-right float-right"
            @click="showErorr = false"
          >
            x
          </button>

          <div
            class="
              mt-4
              flex flex-col
              justify-center
              items-center
              max-w-xs
              mx-auto
            "
          >
            <p class="text-2xl tracking-wide">{{ addErorr }}</p>
          </div>
        </div>
      </div>
    <div v-if="showAlert"
        class="
          fixed
          z-50
          inset-0
          min-h-full
          w-full
          bg-primary-black bg-opacity-75
          flex
          justify-center
          items-center
          overflow-y-hidden">
        <div
          v-if="showAlertLoading"
          class="h-64 flex justify-center items-center">
         <svg
            class="w-10 h-w-10 rounded-full"
            viewBox="0 0 38 38"
            stroke="#fff"
          >
            <g fill="none">
              <g transform="translate(1 1)" stroke-width="2">
                <circle
                  stroke="#E1E7EC"
                  stroke-opacity=".5"
                  cx="18"
                  cy="18"
                  r="18"
                />
                <path stroke="black" d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </div>
        <div v-else class="w-1/3 bg-surface-white p-4 rounded">
          <div
            class="
              mt-8
              flex flex-col
              justify-center
              items-center
              max-w-xs
              mx-auto
            "
          >
            <p class="text-lg tracking-wide">Enter the OTB</p>
            <input 
               v-model="otp"
              class="mt-4 focus:outline-none w-full"
              type="number"
              maxlength="6"
            />
            <div class="flex justify-between items-center w-full mt-6">
              <button
                class="
                  w-4/12
                  mr-2
                  border
                  py-2
                  flex
                  justify-center
                  items-center
                  focus:outline-none
                  hover:text-green-100
                  duration-500
                "
              >
                <svg
                  class="w-6 h-6 stroke-current fill-current"
                  id="Layer_1"
                  enable-background="new 0 0 512 512"
                  height="512"
                  viewBox="0 0 512 512"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <g>
                      <path
                        d="m73.091 247.676h52.349c5.522 0 10-4.477 10-10s-4.478-10-10-10h-52.349c-5.522 0-10 4.477-10 10s4.477 10 10 10z"
                      />
                      <path
                        d="m232.234 227.676h-58.754c-5.522 0-10 4.477-10 10s4.478 10 10 10h58.754c5.522 0 10-4.477 10-10s-4.477-10-10-10z"
                      />
                      <path
                        d="m233.827 296.233c0 5.523 4.478 10 10 10h59.576c5.522 0 10-4.477 10-10s-4.478-10-10-10h-59.576c-5.522 0-10 4.477-10 10z"
                      />
                      <path
                        d="m198.587 296.233c0-5.523-4.478-10-10-10h-115.496c-5.522 0-10 4.477-10 10s4.478 10 10 10h115.496c5.522 0 10-4.477 10-10z"
                      />
                      <path
                        d="m378.001 15.696c-73.872 0-133.971 60.101-133.971 133.974 0 6.605.489 13.097 1.416 19.449h-211.525c-18.704 0-33.921 15.217-33.921 33.922v186.386c0 18.705 15.217 33.922 33.921 33.922h55.528v62.956c0 3.579 1.912 6.884 5.014 8.668 1.544.888 3.266 1.332 4.986 1.332 1.737 0 3.475-.452 5.028-1.356l123.085-71.6h63.285c5.522 0 10-4.477 10-10s-4.478-10-10-10h-65.982c-1.767 0-3.501.468-5.028 1.356l-110.388 64.214v-55.57c0-5.523-4.478-10-10-10h-65.528c-7.676 0-13.921-6.245-13.921-13.922v-186.386c0-7.677 6.245-13.922 13.921-13.922h216.037c16.877 54.696 67.893 94.554 128.043 94.554 31.159 0 59.868-10.691 82.652-28.597v134.351c0 7.546-6.375 13.922-13.922 13.922h-66.095c-5.522 0-10 4.477-10 10s4.478 10 10 10h66.095c18.704 0 33.922-15.217 33.922-33.922v-153.729c19.553-23.294 31.347-53.307 31.347-86.028 0-73.874-60.111-133.974-133.999-133.974zm0 247.976c-62.844 0-113.971-51.141-113.971-114.002 0-62.846 51.127-113.974 113.971-113.974 62.859 0 113.999 51.128 113.999 113.974 0 62.861-51.14 114.002-113.999 114.002z"
                      />
                      <path
                        d="m467.131 131.701c-3.616-4.176-9.934-4.628-14.105-1.013l-7.115 6.161c-5.98-29.965-32.488-52.618-64.197-52.618-36.083 0-65.438 29.356-65.438 65.44 0 36.099 29.355 65.468 65.438 65.468 12.888 0 25.333-3.731 35.99-10.789 4.604-3.05 5.865-9.255 2.815-13.859-3.05-4.605-9.255-5.863-13.858-2.815-7.373 4.883-15.999 7.464-24.947 7.464-25.055 0-45.438-20.397-45.438-45.468 0-25.056 20.384-45.44 45.438-45.44 20.83 0 38.422 14.078 43.78 33.21l-7.373-5.524c-4.422-3.311-10.688-2.413-13.999 2.008-3.312 4.42-2.413 10.688 2.007 13.999l24.403 18.282c1.782 1.335 3.891 1.997 5.995 1.997 2.34 0 4.675-.819 6.547-2.44l23.043-19.954c4.176-3.619 4.629-9.934 1.014-14.109z"
                      />
                      <path
                        d="m73.091 344.791c-5.522 0-10 4.477-10 10s4.478 10 10 10h62.354c5.522 0 10-4.477 10-10s-4.478-10-10-10z"
                      />
                      <path
                        d="m183.485 364.791h224.077c5.522 0 10-4.477 10-10s-4.478-10-10-10h-224.077c-5.522 0-10 4.477-10 10s4.478 10 10 10z"
                      />
                      <path
                        d="m335.742 403.349c-5.522 0-10 4.477-10 10s4.478 10 10 10h.057c5.522 0 9.972-4.477 9.972-10s-4.506-10-10.029-10z"
                      />
                    </g>
                  </g>
                </svg>
              </button>
              <button
                @click="AddProductToCart()"
                title="Resend"
                class="
                  w-8/12
                  ml-2
                  bg-primary-black
                  text-surface-white
                  py-2
                  text-lg
                  tracking-wide
                  focus:outline-none
                  hover:bg-grey-800
                  duration-500
                "
              >
                confirmation
              </button>
            </div>
          </div>
        </div>
</div>

    
      <div class="md:hidden">
        <p class="text-grey-900 text-2xl font-bold">
          Welcome
          <span> {{ fullName }}</span>
        </p>
      </div>

      <section
        class="
          flex flex-col-reverse
          lg:flex-row
          items-start
          lg:space-x-10
          xl:space-x-20
        "
      >
        <div class="w-full mt-10 lg:mt-0 lg:w-1/2">
          <p class="hidden md:block text-grey-900 text-2xl font-bold">
            Welcome
            <span> Muhammad </span>
          </p>

          <div class="hidden md:flex justify-between items-center mt-4">
            <p class="text-grey-700 text-sm">
              Fill the form below to buy your items. or login
            </p>
            <p class="text-green-100 text-sm flex items-center">
              <svg
                class="mr-2"
                width="11"
                height="13"
                viewBox="0 0 11 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.58366 0.333313C3.68079 0.333313 2.13669 1.84087 2.13669 3.6987V5.14101H1.56219C0.768882 5.14101 0.166992 5.79257 0.166992 6.54726V11.4271C0.166992 12.1817 0.768882 12.8333 1.56219 12.8333H9.60512C10.3984 12.8333 11.0003 12.1817 11.0003 11.4271V6.54726C11.0003 5.79257 10.3984 5.14101 9.60512 5.14101H9.03063V3.6987C9.03063 1.84087 7.48653 0.333313 5.58366 0.333313ZM8.04578 5.14101V3.6987C8.04578 2.37191 6.94261 1.29485 5.58366 1.29485C4.22471 1.29485 3.12154 2.37191 3.12154 3.6987V5.14101H8.04578Z"
                  fill="#219653"
                />
              </svg>
              Secure Transaction
            </p>
          </div>

          <div class="mt-12 flex justify-between items-start">
            <div class="">
              <p class="text-grey-900 text-xs uppercase">Your information</p>

              <p class="text-grey-400 text-sm font-medium mt-3">
                {{ fullName }}
              </p>

              <p class="text-grey-400 text-sm font-medium mt-3">
                {{ PhoneNumber }}
              </p>

              <p class="text-grey-400 text-sm font-medium mt-3">
                {{ Email }}
              </p>
            </div>

            <div class="">
              <p class="text-grey-900 text-xs uppercase">Location</p>

              <p class="text-grey-400 text-sm font-medium mt-3">
                {{ city }}, {{ country }}
              </p>

              <p class="text-grey-400 text-sm font-medium mt-3">
                {{ address }}
              </p>
            </div>
          </div>

          <div class="mt-10">
            <router-link
              :to="{ name: 'profile' }"
              class="
                w-full
                bg-surface-white
                text-xs
                md:text-base
                font-medium
                tracking-widest
                py-3
                px-12
                text-primary-black
                rounded
                focus:outline-none
                hover:bg-primary-black hover:text-surface-white
                border
                hover:border-grey-900
                transform
                duration-300
              "
            >
              EDIT
            </router-link>
          </div>

          <div class="mt-12">
            <p class="text-grey-900 text-sm uppercase">Payment</p>
            <input
              type="tel"
              class="
                mt-6
                border-b border-grey-500
                w-full
                pb-3
                focus:border-primary-black focus:outline-none
              "
              placeholder="911678***"
              v-model="PhoneNumberSadad"
            />
            <input
              type="tel"
              class="
                mt-6
                border-b border-grey-500
                w-full
                pb-3
                focus:border-primary-black focus:outline-none
              "
              placeholder="BirthYear"
              v-model="BirthYear"
            />
            <div class="mt-10 flex items-center space-x-8">
              <div class="w-full flex flex-col">
                <button
                  @click="TypePaymentMethod('sadad')"
                  class="
                    border-2 border-grey-900
                    flex
                    justify-center
                    items-center
                    py-4
                    rounded
                    focus:outline-none
                    hover:border-grey-700
                    transform
                    duration-300
                  "
                >
                  <img src="../../assets/Pay/Sadad.png" alt="" class="h-14" />
                </button>
                <!-- <span
                  class="text-center mt-4 text-grey-900 text-base font-medium"
                  >Sadad Pay</span
                > -->
              </div>
            </div>
          </div>

          <div class="mt-12">
            <button
              @click="SadadValidate()"
              class="
                w-full
                bg-grey-900
                text-xs
                md:text-sm
                font-medium
                tracking-widest
                p-3
                text-surface-white
                rounded
                focus:outline-none
                hover:bg-surface-white hover:text-grey-900
                border-2
                hover:border-grey-900
                transform
                duration-300
              "
            >
             Payment via Sadad 
            </button>
          </div>
        </div>

        <div class="w-full lg:w-1/2">
          <p class="hidden md:block text-grey-900 text-2xl font-bold">
            Summmary
          </p>

          <div
            class="
              mt-4
              border border-grey-300
              px-6
              pb-8
              divide-y divide-grey-300
            "
          >
            <div
              class="md:hidden text-left flex justify-between items-start my-7"
            >
              <div class="">
                <p class="text-xs text-primary-black uppercase">total</p>
                <p class="mt-2 font-bold text-3xl text-primary-black uppercase">
                                    {{ cartTotalPrice }}

                </p>
              </div>

              <div class="flex flex-col items-end">
                <p class="text-green-100 text-sm flex items-center">
                  <svg
                    class="mr-2"
                    width="11"
                    height="13"
                    viewBox="0 0 11 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.58366 0.333313C3.68079 0.333313 2.13669 1.84087 2.13669 3.6987V5.14101H1.56219C0.768882 5.14101 0.166992 5.79257 0.166992 6.54726V11.4271C0.166992 12.1817 0.768882 12.8333 1.56219 12.8333H9.60512C10.3984 12.8333 11.0003 12.1817 11.0003 11.4271V6.54726C11.0003 5.79257 10.3984 5.14101 9.60512 5.14101H9.03063V3.6987C9.03063 1.84087 7.48653 0.333313 5.58366 0.333313ZM8.04578 5.14101V3.6987C8.04578 2.37191 6.94261 1.29485 5.58366 1.29485C4.22471 1.29485 3.12154 2.37191 3.12154 3.6987V5.14101H8.04578Z"
                      fill="#219653"
                    />
                  </svg>
                  Secure Transaction
                </p>

                <button
                  class="
                    underline
                    text-grey-900 text-sm
                    font-semibold
                    mt-3
                    focus:outline-none
                  "
                >
                  View Details
                </button>
              </div>
            </div>

            <div
              class="py-6 flex justify-between items-start"
              v-for="(cart, index) in carts"
              :key="index"
            >
              <div class="w-1/4">
                <img :src="cart.productImage" alt="" class="h-24 w-full" />
              </div>
              <div class="w-2/4">
                <p class="text-sm text-grey-900">{{ cart.productName }}</p>
                <p class="mt-2 text-sm text-grey-400">
                  Quantity <span> {{ cart.productQty }}</span>
                </p>
                <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-y-4 mt-4">
                  <div class="text-sm text-grey-400 flex items-center">
                    <span v-if="cart.size.sizeName != null">
                      Size
                      <span
                        class="
                          ml-2
                          border border-grey-300
                          focus:outline-none
                          uppercase
                          px-3
                          py-1
                          text-xs
                        "
                        >{{ cart.size.sizeName }}</span
                      >
                    </span>
                    <span
                      v-if="cart.size.width != null && cart.size.height != null"
                    >
                      H/W
                      <span
                        class="
                          ml-2
                          border border-grey-300
                          focus:outline-none
                          uppercase
                          px-3
                          py-1
                          text-xs
                        "
                        >{{ cart.size.height }}/{{ cart.size.width }}</span
                      >
                    </span>
                  </div>
                  <div class="text-sm text-grey-400 flex items-center">
                    Color
                    <span
                      v-bind:style="{ backgroundColor: cart.color.hexadecimal }"
                      class="
                        ml-2
                        inline-block
                        w-6
                        h-6
                        rounded-full
                        bg-primary-black
                        focus:outline-none
                      "
                    ></span>
                  </div>
                </div>
              </div>
              <div class="w-1/4 text-sm text-grey-900 font-semibold">
                {{ cart.productQty }} x ${{ cart.productPrice }}
              </div>
              <div
                class="text-grey-900 font-semibold text-lg"
                @click="deletefromcart(cart, index)"
              >
                X
              </div>
            </div>

            <div class="hidden md:block text-center">
              <p class="text-xs text-primary-black uppercase mt-8">total</p>
              <p class="mt-2 font-bold text-3xl text-primary-black uppercase">
                {{ cartTotalPrice }}
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
export default {
  components: {},
  computed: {
    // carts() {
    //   return this.$store.state.cart;
    // },
    cartTotalPrice() {
      return this.$store.getters.cartTotalPrice;
    },
  },
  created() {
    if (this.$authenticatedUser.userId == 0) {
      this.$router.push("guest-checkout");
    }
  },
  data() {
    return { 
      showAlert: false,
      showAlertLoading: false,
      showErorr: false,
      carts: JSON.parse(localStorage.getItem("cart")),
      paymentMethod: "",
      addSuccessed: "",
      addErorr: "",
      fullName: this.$authenticatedUser.fullName,
      PhoneNumber: this.$authenticatedUser.phoneNo,
      Email: this.$authenticatedUser.userName,
      address: this.$authenticatedUser.address,
      city: this.$authenticatedUser.city,
      country: this.$authenticatedUser.country,
      BirthYear: "",
      transactionId: "",
      otp: "",
      statuspay: 0,
      PhoneNumberSadad: "",
      sadadlogId: 0,
    };
  },
  methods: {
      SadadValidate() {
      this.showAlertLoading = true;
      this.showAlert = true;

      var SadadValidate = {
        Msisdn: this.PhoneNumberSadad,
        BirthYear: this.BirthYear,
        Amount: this.cartTotalPrice,
        Category: 20,
      };
      this.$http.productsService
        .SadadValidate(SadadValidate)
        .then((res) => {
          this.addSuccessed = res.data.result.message;
          this.sadadlogId = res.data.result.sadadLogId;
          var Responses = res.data.result.result;
          var b = JSON.stringify(Responses);
          var Respon = b.replace(/\\/g, "");
          var Responobj = Respon.slice(1, -1);
          Responobj = JSON.parse(Responobj);
          this.transactionId = Responobj["result"].transactionId;
          this.statuspay = 1;
          this.showAlertLoading = false;
          this.showAlert = true;
        })
        .catch((err) => {
          this.showAlert = false;
          this.showAlertLoading = false;
          this.showErorr = true;

          this.addErorr = err.response.data.message;
        });
    },
    AddProductToCart() {
      var OrderInfo = {
        cartTotalPrice: this.cartTotalPrice,
        firstName: this.fullName,
        lastName: this.fullName,
        phoneNumber: this.PhoneNumber,
        email: this.Email,
        address: this.address,
        city: this.city,
        state: this.country,
        ordersProducts: this.carts,
        transactionId: this.transactionId,
        OTP: this.otp,
        sadadlogId: this.sadadlogId,
      };
      this.$http.productsService
        .AddProductToCart(OrderInfo)
        .then((res) => {
          this.addSuccessed = res.data.result.message;
        })
        .catch((err) => {
          this.addErorr = err.message;
        });
    },
    TypePaymentMethod(paymentMethod) {
      if (paymentMethod == "edfali") {
        this.paymentMethod = "edfali";
      } else if (paymentMethod == "sadad") {
        this.paymentMethod = "sadad";
      }
    },
    deletefromcart(cart, index) {
      this.$store.commit("DELETE_FROM_CART", cart);
      this.carts.splice(index, 1);
      this.cartTotalPrice();
    },
  } 
};
</script>
